import {Card} from "react-bootstrap";

export const HomeView = () => (
    <>
        <div
            className="d-flex justify-content-center"
            style={{ margin: "30px"}}
        >
        <Card className="text-center">
        <h1>RCP Architekt  </h1>
        <Card.Body>
            <Card.Title>Witam na stronie platformy.</Card.Title>
                <h6>Platforma służy do rejestracji czasu pracy praconików pracowni.</h6>
                <h6>Pozwala zarządzać zespołem.</h6>

            <h3>Z uwagi na trudności w pełnej komunikacji frontu z backendem, nie każda przeglądarka obsługuje aplikacje poprawnie.</h3>
            <h3>Walka jednak trwa...</h3>
                <h6>UŻYTKOWNIK TESTOWY: hasło i email: test@test</h6>
            <h6>Służy wyłącznie do celów testowych.</h6>
            </Card.Body>
            <Card.Footer className="text-muted"></Card.Footer>
        </Card>
        </div>
      </>
);

